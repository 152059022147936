import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getConfig } from '@/Util/global'

export const prefixProxyAdvertiseEndpoint = (url: string) => {
  return getConfig().ENV === 'local' ? `/proxy-advertise-api${url}` : url
}

const convertBaseUrl = () => {
  return getConfig()?.API_URL_ADVERTISE
}

interface UpdateSessionRequest {
  renew: boolean
  userId?: string | null
}

export enum AdvertiseSpaceEnum {
  HOME_BANNER = 'home_banner',
  HOME_TAB_POPULAR_LIST = 'home_tab_popular_list',
  CHATBOT_HISTORY = 'chatbot_history',
  EXPLORE_TITLE = 'explore_title',
  EXPLORE_LIST = 'explore_list',
  TEXT_SEARCH_TITLE = 'text_search_title',
  TEXT_SEARCH_LIST = 'text_search_list',
  EXPLORE_BANNER_CATEGORY = 'explore_banner_category',
  EXPLORE_BANNER_BRAND = 'explore_banner_brand',
  EXPLORE_BANNER_COUNTRY = 'explore_banner_country',
  EXPLORE_BANNER_FLAVOR = 'explore_banner_flavor',
  EXPLORE_BANNER_PARING = 'explore_banner_paring',
}

interface GetAdvertiseInfoRequest {
  space: Array<AdvertiseSpaceEnum>
}

interface AdvertiseImage {
  kind: string
  url: string
}

interface AdvertiseContent {
  brand: string
  title: string
  description: string
}

export enum templateModeEnum {
  IMAGE = 'a',
  CARD = 'b',
}

export enum destinationTypeEnum {
  INTERNAL_CAMPAIGN = 'internal_campaign',
  EXTERNAL_SITE = 'external_site',
  INTERNAL_PAGE = 'internal_page',
}

export interface AdvertiseItem {
  id: string
  templateMode: templateModeEnum
  images: AdvertiseImage[]
  impressionUrl: string
  destinationType: destinationTypeEnum
  destinationUrl: string
  contents: AdvertiseContent
}

export const DefaultAdvertiseItem: AdvertiseItem = {
  id: '',
  templateMode: templateModeEnum.IMAGE,
  images: [],
  impressionUrl: '',
  destinationType: destinationTypeEnum.EXTERNAL_SITE,
  destinationUrl: '',
  contents: {
    brand: '',
    title: '',
    description: '',
  },
}

export interface GetAdvertiseInfoResponse {
  data: {
    [key in AdvertiseSpaceEnum]?: AdvertiseItem[]
  }
}

interface PostRedirectADClickRequest {
  id: string
  space: AdvertiseSpaceEnum
  destinationUrl: string
  disableRedirect?: boolean
}

export const advertiseApi = createApi({
  reducerPath: 'advertise',
  baseQuery: fetchBaseQuery({
    baseUrl: convertBaseUrl(),
    prepareHeaders: async (headers, { getState }: any) => {
      const { language: locale } = getState().theme
      if (locale) {
        headers.set('Accept-Language', locale)
      }
      return headers
    },
  }),
  endpoints: builder => ({
    // 啟動廣告(開啟網頁或是app的時候呼叫，如果帳號有登入或沒登入 renew 都設為 true。當使用者進行登入的動作完成後，再呼叫一次，並將 renew 設為 false)
    postUpdateSession: builder.mutation<void, UpdateSessionRequest>({
      query: body => ({
        url: prefixProxyAdvertiseEndpoint('/v1/sessions:update'),
        method: 'POST',
        body,
      }),
    }),
    // 取得廣告資訊
    getAdvertiseInfo: builder.query<
      GetAdvertiseInfoResponse,
      GetAdvertiseInfoRequest
    >({
      query: params => ({
        url: prefixProxyAdvertiseEndpoint('/v1/advertises'),
        method: 'GET',
        params: {
          space: params.space.join(','),
        },
      }),
    }),
    // 內部廣告轉址，因為沒有透過後端紀錄 log 這邊要另外再打這支
    getRedirectADClick: builder.query<void, PostRedirectADClickRequest>({
      query: params => ({
        url: prefixProxyAdvertiseEndpoint(`/v1/advertise_clicks/${params.id}`),
        method: 'GET',
        params,
      }),
    }),
  }),
})
