import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type AdvertiseState = {
  userClickedAdvertise: {
    id: string
    currentIndex: number
  }[]
}

const initialState = {
  userClickedAdvertise: [],
}

const slice = createSlice({
  name: 'advertise',
  initialState: initialState as AdvertiseState,
  reducers: {
    userClickedAdvertise: (
      state,
      action: PayloadAction<{ id: string; currentIndex: number }>,
    ) => {
      if (state.userClickedAdvertise.length === 0) {
        state.userClickedAdvertise.push(action.payload)
        return
      }

      const hasDuplicate = state.userClickedAdvertise.some(
        item => item.id === action.payload.id,
      )
      if (!hasDuplicate) {
        state.userClickedAdvertise.push(action.payload)
      }
    },
  },
})

export const advertiseReducer = slice.reducer
export const advertiseActions = slice.actions

export default slice.reducer
