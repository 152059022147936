import React, { useCallback, useEffect, useState } from 'react'
import {
  View,
  Image,
  Text,
  StyleSheet,
  ScrollView,
  FlatList,
  ActivityIndicator,
  Dimensions,
} from 'react-native'
import { useSelector } from 'react-redux'
import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { FadeInView, Header, ReviewCard } from '@/Components'
import { spiritsApi } from '@/Services/modules/spirits'
import { useTranslation } from 'react-i18next'
import userApi from '@/Services/modules/users'
import { RootState } from '@/Store'
import { navigateAndSimpleReset } from '@/Navigators/utils'
import noteApi from '@/Services/modules/note'
import { NestedSetType } from '@/Services/modules/note/note.type'
import { DownLoadSign } from '@/Components'
import { PagesEnum } from '@/Navigators/Application'

const ReviewNoteListContainer = ({ route, navigation }: any) => {
  const { id } = route.params
  const { Layout, Gutters, Images, Fonts } = useTheme()
  const { t } = useTranslation()
  const { language } = useSelector((state: RootState) => state.theme)

  // 取得 me 資料
  const { data: getMeData, isSuccess: getMeSuccess } = userApi.useGetMeQuery()

  // 取得風味資訊
  noteApi.useGetNestedSetQuery({
    type: NestedSetType.FLAVOR_WHEEL,
  })

  // 取得該支酒的個人資訊，包括是否有收藏、個人評論
  const { data: personalSpiritData, isLoading: personalSpiritDataIsLoading } =
    spiritsApi.useGetSpiritPersonalInfoQuery(
      {
        id: route.params?.id,
      },
      {
        skip: !getMeData,
      },
    )

  const personalReviewData = personalSpiritData?.data?.myNotes[0]
    ? {
        ...personalSpiritData?.data?.myNotes[0],
      }
    : {}

  // 取得酒的 review notes list
  const {
    data: reviewNotesListData,
    isSuccess: reviewNotesListIsSuccess,
    isLoading: reviewNotesListIsLoading,
  } = spiritsApi.useGetSpiritNotesQuery({
    id: id,
  })

  // lazy 取得酒的 review notes list
  const [
    getReviewNotesListRequest,
    {
      isSuccess: isLazyGetReviewNotesListSuccess,
      isFetching: isLazyGetReviewNotesListFetching,
      data: lazyGetReviewNotesListData,
    },
  ] = spiritsApi.useLazyGetSpiritNotesQuery()

  const [reviewNoteList, setReviewNoteList] = useState<any[]>(
    reviewNotesListData?.data || [],
  )
  const [cursor, setCursor] = useState<string>('')

  const styles = getStyle()

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }
    navigateAndSimpleReset(PagesEnum.Main)
  }, [navigation])

  // 如果沒登入要導出去
  useEffect(() => {
    if (!getMeData && getMeSuccess) {
      navigation.goBack()
    }
  }, [getMeData, navigation, getMeSuccess])

  useEffect(() => {
    if (reviewNotesListIsSuccess) {
      // 因後端機制，須由前端將使用者自己的評論移除
      const reviewWithoutUsers = reviewNotesListData?.data?.filter(
        (item: any) => {
          return item?.user?.id !== getMeData?.id
        },
      )
      setReviewNoteList(reviewWithoutUsers || [])
      setCursor(reviewNotesListData?.meta?.nextCursor || '')
    }
  }, [reviewNotesListIsSuccess, reviewNotesListData, getMeData])

  useEffect(() => {
    if (isLazyGetReviewNotesListSuccess) {
      // 因後端機制，須由前端將使用者自己的評論移除
      const reviewWithoutUsers = lazyGetReviewNotesListData?.data?.filter(
        (item: any) => {
          return item?.user?.id !== getMeData?.id
        },
      )
      setReviewNoteList(prevBoozesList => {
        return [...prevBoozesList, ...(reviewWithoutUsers || [])]
      })
      setCursor(lazyGetReviewNotesListData?.meta?.nextCursor || '')
    }
  }, [isLazyGetReviewNotesListSuccess, lazyGetReviewNotesListData, getMeData])

  const handleFetchMoreData = () => {
    if (cursor) {
      getReviewNotesListRequest({
        id,
        cursor,
      })
    }
  }

  const renderListLoading = () => {
    return (
      <View>
        {isLazyGetReviewNotesListFetching && (
          <ActivityIndicator size="large" color={Colors.primary} />
        )}
      </View>
    )
  }

  // 使用者自己的評論
  const renderUserReviewItem = ({ item }: any) => {
    const {
      content,
      rating,
      createdAt,
      updatedAt,
      user,
      flavors,
      id: reviewId,
      counters,
      likeClicked,
      image,
    } = item
    return (
      <ReviewCard
        content={content}
        likeClicked={likeClicked}
        rating={rating}
        createdAt={createdAt}
        updatedAt={updatedAt}
        flavors={flavors}
        user={user}
        showCTA
        showUser={false}
        language={language}
        isSingle
        showWholeFlavors
        id={reviewId}
        spiritId={route?.params?.id}
        counters={counters}
        getMeData={getMeData}
        imagePath={image?.url}
        advancedRatings={item?.advancedRatings}
      />
    )
  }

  // 其他人的評論
  const renderReviewItem = ({ item }: any) => {
    const {
      content,
      rating,
      createdAt,
      updatedAt,
      user,
      flavors,
      id: reviewId,
      counters,
      likeClicked,
      image,
    } = item
    return (
      <ReviewCard
        likeClicked={likeClicked}
        content={content}
        rating={rating}
        createdAt={createdAt}
        updatedAt={updatedAt}
        flavors={flavors}
        user={user}
        showCTA
        language={language}
        id={reviewId}
        spiritId={route?.params?.id}
        counters={counters}
        getMeData={getMeData}
        imagePath={image?.url}
        advancedRatings={item?.advancedRatings}
      />
    )
  }

  // TODO: 往上拉 reFresh
  return (
    <View style={[Layout.fill, { backgroundColor: Colors.background.default }]}>
      <ScrollView style={[{ height: Dimensions.get('window').height }]}>
        <Header
          title={t('reviewNoteList.title')}
          leftIcon={
            <Image
              style={[styles.closeIcon]}
              source={Images.close}
              resizeMode="contain"
            />
          }
          leftIconPress={handleGoBack}
          rightEmptyIconWidth="50"
        />

        <FadeInView duration={500} style={Layout.fill}>
          <View style={[Layout.fill]}>
            {reviewNotesListIsLoading || personalSpiritDataIsLoading ? (
              <View style={[Layout.fill, Layout.colCenter, Layout.fullHeight]}>
                <ActivityIndicator size="large" color={Colors.primary} />
              </View>
            ) : (
              <View style={[Layout.fill]}>
                {!!Object.keys(personalReviewData)?.length && (
                  <View
                    style={[
                      Gutters.regularHPadding,
                      Gutters.regularVPadding,
                      { backgroundColor: Colors.background.surface },
                    ]}
                  >
                    <Text
                      style={[
                        Fonts.size16,
                        Fonts.weight700,
                        Gutters.smallBMargin,
                        { color: Colors.white },
                      ]}
                    >
                      {t('reviewNoteList.section1')}
                    </Text>
                    <FlatList
                      keyExtractor={(_item, index) => index.toString()}
                      data={[personalReviewData]}
                      renderItem={renderUserReviewItem}
                    />
                  </View>
                )}
                <View style={styles.divider} />
                <View
                  style={[
                    Gutters.regularHPadding,
                    Gutters.regularVPadding,
                    { backgroundColor: Colors.background.surface },
                  ]}
                >
                  <Text
                    style={[
                      Fonts.size16,
                      Fonts.weight700,
                      Gutters.smallBMargin,
                      { color: Colors.white },
                    ]}
                  >
                    {t('reviewNoteList.section2')}
                  </Text>
                  {reviewNoteList && (
                    <FlatList
                      keyExtractor={(_item, index) => index.toString()}
                      data={reviewNoteList}
                      renderItem={renderReviewItem}
                      refreshing
                      onEndReached={handleFetchMoreData}
                      onEndReachedThreshold={3}
                      ListFooterComponent={renderListLoading}
                    />
                  )}
                </View>
              </View>
            )}
          </View>
        </FadeInView>
      </ScrollView>
      <DownLoadSign />
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    closeIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    divider: {
      width: '100%',
      height: 8,
      backgroundColor: Colors.background.default,
    },
  })

export default ReviewNoteListContainer
