import { Meta } from '../spirits/spirits.type'

export interface NoteDetail {
  id: string
  content: string
  flavors: string[]
  rating: number
  advancedRatings: {
    nose: number
    taste: number
    finish: number
  }
  user: {
    id: number
    name: string
    avatar: string
    notesCount: number
    title: {
      imageUrl: string
      name: string
    }
  }
  counters: {
    likers: number
    comments: number
  }
  likeClicked: boolean
  likersCount: number
  createdAt: string
  updatedAt: string
  image: {
    url: string
  }
  expertise: {
    glass: string
    pairing: string[]
    price: number
    tastingVolume: number
    bottledAge: number
    batchNumber: string
    purchasePlace: string
  }
  spirit: {
    id: string
    title: string
    titleUrlSlug: string
    imageUrl: string
    brand: string
    ratings: {
      average: number
      count: number
    }
    collected: boolean
  }
}

export interface NoteDetailResponse {
  data: NoteDetail
}

export interface NoteComment {
  id: string
  content: string
  commentator: {
    id: number
    name: string
    avatar: string
  }
  createdAt: string
}

export interface NoteCommentResponse {
  data: NoteComment[]
  meta: {
    nextCursor: string | null
    perPage: number
  }
}

export interface CommentTemplateResponse {
  data: {
    id: string
    name: string
    content: string
  }
}

export interface PostCommentTemplateRequest {
  name: string
  content: string
}

export interface PatchCommentTemplateRequest
  extends PostCommentTemplateRequest {
  id: string
}

export enum DropdownsType {
  GLASS = 'glass',
  PAIRING = 'pairing',
  BRAND = 'brand',
}

export interface GetNotesDropdownsOptionsRequest {
  type: DropdownsType
  locale: string
}

export interface GetNotesDropdownsOptionsResponse {
  data: { [key: string]: string }
}

export interface PostNoteImageRequest {
  id: string
  path: string
}

export enum NestedSetType {
  FLAVOR_WHEEL = 'flavor-wheel',
  LOCATION = 'location',
  CATEGORY = 'category',
}

export interface GetNestedSetRequest {
  type: NestedSetType
}

export interface GetUserNotesResponse {
  data: {
    id: string
    content: string
    flavors: string[]
    rating: number
    advancedRatings?: {
      nose: number
      taste: number
      finish: number
    }
    image: {
      kind: string
      url: string
    }
    spirit: {
      id: string
      title: string
      titleUrlSlug: string
      imageUrl: string
      brand: string
      ratings: {
        average: number
        count: number
      }
      collected: boolean
    }
    createdAt: string
    updatedAt: string
  }[]
  meta: {
    nextCursor: string | null
    perPage: number
  }
}

export interface GetFollowingNotesRequest {
  page: number
  perPage?: number
}

export interface GetFollowingNotesResponse {
  data: Array<{
    id: string
    content: string
    flavors: Array<string>
    rating: number
    image: {
      kind: string
      url: string
    }
    user: {
      id: string
      name: string
      avatar: string
      notesCount: number
      title: {
        name: string
        imageUrl: string
      }
    }
    counters: {
      comments: number
      likers: number
    }
    likeClicked: boolean
    createdAt: string
    updatedAt: string
  }>
  meta: Meta
}

export enum UserType {
  EXPERT = 'expert',
}

export interface GetLatestNotesRequest {
  cursor?: string
  perPage?: number
  userType?: UserType
}

export interface GetLatestNotesResponse extends GetFollowingNotesResponse { }
