import React, { useCallback, useEffect, useState } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { useTheme } from '@/Hooks'
import { RootState } from '@/Store'
import { advertiseActions } from '@/Store/Advertise'
import { AdvertiseItem, AdvertiseSpaceEnum } from '@/Services/advertiseApi'
import { Colors } from '@/Theme/Variables'

import AdvertiseComponent from './AdvertiseComponent'

interface AdvertiseListProps {
  leadingItem: any
  listData: any[]
  adData: AdvertiseItem[]
  space: AdvertiseSpaceEnum
}

interface AdvertiseDataType extends AdvertiseItem {
  stopShowIndex?: number
}

const SHOW_AD_INTERVAL = 8

const AdvertiseList = ({
  leadingItem,
  adData,
  listData,
  space,
}: AdvertiseListProps) => {
  const { Gutters } = useTheme()
  const dispatch = useDispatch()

  const { userClickedAdvertise } = useSelector(
    (state: RootState) => state.advertiseStore,
  )

  const [advertiseData, setAdvertiseData] = useState<AdvertiseDataType[]>([])

  useEffect(() => {
    if (adData.length > 0) {
      setAdvertiseData(adData)
    }
  }, [adData])

  const handleClickAD = useCallback(
    (id: string, currentIndex: number) => {
      dispatch(
        advertiseActions.userClickedAdvertise({
          id,
          currentIndex,
        }),
      )
    },
    [dispatch],
  )

  const hideClickedAD = useCallback(
    (id: string, currentIndex: number) => {
      const userClickedAD = userClickedAdvertise.find(item => item.id === id)
      return userClickedAD ? currentIndex > userClickedAD.currentIndex : false
    },
    [userClickedAdvertise],
  )

  if (advertiseData.length === 0) {
    return null
  }

  const currentIndex = listData.indexOf(leadingItem)
  const showADItem =
    Math.floor(currentIndex / SHOW_AD_INTERVAL) % advertiseData.length

  if (hideClickedAD(advertiseData[showADItem].id, currentIndex)) {
    return null
  }

  const renderAdvertise = (adIndex: number) => (
    <View style={[Gutters.tinyBMargin]}>
      <AdvertiseComponent
        data={advertiseData[adIndex]}
        space={space}
        customSponsorTagStyle={
          currentIndex !== 0
            ? { backgroundColor: Colors.background.onSurface }
            : undefined
        }
        onClick={(id: string) => handleClickAD(id, currentIndex)}
      />
    </View>
  )

  // 列表數量小於 SHOW_AD_INTERVAL 且是最後一筆時，顯示第一個廣告
  if (
    listData.length < SHOW_AD_INTERVAL &&
    currentIndex === listData.length - 1
  ) {
    return renderAdvertise(0)
  }

  // 每 SHOW_AD_INTERVAL 個項目後顯示一個廣告（排除第0個位置）
  if (currentIndex !== 0 && (currentIndex + 1) % SHOW_AD_INTERVAL === 0) {
    return renderAdvertise(showADItem)
  }

  return null
}

export default React.memo(AdvertiseList)
